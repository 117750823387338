import { isTouch } from '../helpers'
import {
  NO_TOUCH,
  // IS_READY,
  IS_LOADED,
} from '../constants'

function setTouch() {
  if (!isTouch) {
    document.documentElement.classList.add(NO_TOUCH)
  }
}

// function setReady() {
//   document.documentElement.classList.add(IS_READY)
// }

function setLoaded() {
  document.documentElement.classList.add(`is-${IS_LOADED}`)
}

export default function setHTMLClassNames() {
  setTouch()
  // setReady()
  setLoaded()
}

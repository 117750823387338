import classNames from '../classNames'
import Validator from '../components/Validator/Validator'
import toggleSelectsFocus from './toggleSelectsFocus'
import { setBrandSelects, setServiceSelects } from './setSelectsValues'
import { messages } from '../components/Validator/utils'
import { HAS_ERROR } from '../constants'

const ACTION_ORDER = '/order.php'
const ACTION_MAIL = '/mail.php'
const METHOD = 'POST'

export default app => {
  const forms = [...document.querySelectorAll(`.${classNames.form}`)]
  if (!forms.length) return

  const resetForm = form => {
    const telInput = form.querySelector('[type="tel"]')
    const maskInput = app.masks.find(({ input } = {}) => input === telInput)
    const popup = form.closest(`.${classNames.popup}`)

    if (maskInput) {
      maskInput.mask.value = ''
      maskInput.inputValue = ''
    }
    form.reset()

    if (!popup) return

    const name = `#${popup.id}`
    const btn = app.popup.btns.find(
      link =>
        link.getAttribute('href') === name &&
        link.hasAttribute('data-service') &&
        link.hasAttribute('data-brand')
    )

    if (btn) {
      const serviceName = btn.dataset.service
      const brandName = btn.dataset.brand

      if (serviceName) {
        setServiceSelects(app, popup, serviceName)
      }

      if (brandName) {
        setBrandSelects(popup, brandName)
        toggleSelectsFocus(app)
      }
    }
  }

  const openModal = () => {
    if (app.popup) {
      const thanksPopup = app.popup.popups.find(({ id }) => id === 'popup-thanks')

      app.popup.openTarget(thanksPopup)
    }
  }

  const handleForm = async (e, callback) => {
    e.preventDefault()
    const form = e.currentTarget

    const formData = new FormData(form)
    if (formData.get('userfullname') || formData.get('userfullage') || formData.get('userphone')) {
      return
    }

    const url = form.dataset.form === 'order' ? ACTION_ORDER : ACTION_MAIL

    try {
      await fetch(url, {
        method: METHOD,
        body: formData,
      })

      callback(form)
    } catch (error) {
      console.error('server error', error)
    }
  }

  const onSubmit = validator => e => {
    if (Object.keys(validator.errors).length > 0) return
    const { currentTarget } = e

    const isOrderForm = currentTarget.dataset.form === 'order'
    const isMailForm = currentTarget.dataset.form === 'mail'

    if (isOrderForm) {
      handleForm(e, form => {
        openModal()

        resetForm(form)
      })
    }

    if (isMailForm) {
      handleForm(e, form => {
        openModal()
        resetForm(form)
      })
    }
  }

  forms.forEach(currForm => {
    const validator = new Validator(
      app,
      currForm,
      {
        name: 'required',
        email: 'email',
        phone: 'phone',
        address: 'required',
        service: 'required',
        brand: 'required',
        'vehicle-number': 'required',
      },
      {
        inputEvents: ['change', 'input'],
      }
    )

    validator.addError = input => {
      const wrap = input.closest('.input')
      wrap.classList.add(`input--${HAS_ERROR}`)

      let message = input.dataset.message || messages.default[app.LANGUAGE]

      if (input.name === 'email' && input.value !== '') {
        message = messages.email[app.LANGUAGE]
      }

      if (input.name === 'phone' && input.value !== '') {
        message = messages.phone[app.LANGUAGE]
      }

      const label =
        wrap.querySelector(`.input__label--${HAS_ERROR}`) || document.createElement('label')
      label.innerHTML = message

      if (label.classList.contains(`input__label--${HAS_ERROR}`)) return

      label.className = `input__label input__label--${HAS_ERROR}`
      wrap.appendChild(label)
    }
    validator.removeError = input => {
      const wrap = input.closest('.input')
      wrap.classList.remove(`input--${HAS_ERROR}`)
      const label = wrap.querySelector(`.input__label--${HAS_ERROR}`)
      if (label) label.parentNode.removeChild(label)
    }
    validator.init()

    currForm.addEventListener('submit', onSubmit(validator))
  })
}

import classNames from '../../classNames'

export default async app => {
  const tabs = [...document.querySelectorAll(`.${classNames.tabs.wrap}`)]
  if (!tabs.length) return

  const { default: Tabs } = await import(/* webpackChunkName: "Tabs" */ './Tabs')

  app.tabs = new Tabs()
  app.tabs.init()
}

import { checkEmail, checkPhone, checkPresence } from './utils'

export default class Validator {
  constructor(app, form, constraints, options = {}) {
    this.form = form
    this.constraints = constraints
    this.options = {
      inputEvents: options.inputEvents || ['change'],
    }
    // this.inputs = [...this.form.querySelectorAll('input, select, textarea')]
    this.app = app
  }

  get errors() {
    return this.validate(this.constraints)
  }

  get inputs() {
    return [...this.form.querySelectorAll('input, select, textarea')]
  }

  handleFormSubmit(e) {
    const keys = Object.keys(this.errors)
    const errorsInputs = []

    if (keys.length) {
      keys.forEach(key => {
        // Use `data-style` to detect if field is required.
        // Do not use `required` word to full parsing robots.
        const inputs = [...this.form.querySelectorAll(`[data-style="true"][name="${key}"]`)]

        inputs.forEach(input => {
          if (input.name === key) {
            e.preventDefault()
            errorsInputs.push(input, this.errors)
            if (this.addError) {
              this.addError(input, this.errors)
            }
          }
        })
      })
      if (this.addSubmitError) {
        this.addSubmitError(this.form, errorsInputs, this.errors)
      }
    } else {
      if (this.removeAllErrors) this.removeAllErrors(e, this.form)
      if (this.showSuccess) this.showSuccess(e, this.form)
    }
  }

  handleInputEvent(e) {
    const input = e.target
    const eventName = e.type

    if (!input) return
    const error = this.getErrorInput(input, this.constraints[input.name])

    if (eventName === 'change' && error) {
      if (this.addError) this.addError(input, this.errors)
    } else if (!error && this.removeError) this.removeError(input, this.errors)
  }

  validate(constraints) {
    const errors = {}
    this.inputs.forEach(input => {
      const shouldValidate = input.dataset.style === 'true'
      if (!shouldValidate) return

      const { name } = input
      const inputWithError = this.getErrorInput(input, constraints[name])
      if (inputWithError) errors[name] = input
    })
    return errors
  }

  getErrorInput(input, constraint) {
    if (constraint === 'required' && !checkPresence(input)) {
      return input
    }
    if (constraint === 'email' && !checkEmail(input)) {
      return input
    }
    if (
      constraint === 'phone' &&
      !checkPhone(
        this.app.masks.find(({ input: i }) => i === input),
        input
      )
    ) {
      return input
    }
    if ({}.toString.call(constraint).toLowerCase().indexOf('function') > -1 && !constraint(input)) {
      return input
    }
    return null
  }

  _validate() {
    this.form.addEventListener('submit', this.handleFormSubmit.bind(this))
    this.options.inputEvents.forEach(eventName => {
      this.form.addEventListener(eventName, this.handleInputEvent.bind(this))
    })
  }

  init() {
    this._validate()
  }
}

import { OFFLINE } from '../../constants'
import InfoPanel from './InfoPanel'
import messages from './messages'

export default class Offline {
  constructor(app) {
    const { dom, LANGUAGE } = app

    this.app = app
    this.dom = dom
    this.LANGUAGE = LANGUAGE

    this.panelHeight = 0
  }

  renderInfoPanel() {
    const tpl = document.createElement('template')
    tpl.innerHTML = InfoPanel({ text: messages[this.LANGUAGE] })
    this.infoPanel = tpl.content.cloneNode(true).firstElementChild
  }

  insertInfoPanel() {
    if (!this.infoPanel) return
    const { body: BODY, root: DOC } = this.dom

    BODY.insertBefore(this.infoPanel, BODY.firstElementChild)
    DOC.classList.add(`is-${OFFLINE.toLowerCase()}`)

    this.panelHeight = this.infoPanel.offsetHeight
  }

  removeInfoPanel() {
    const { body: BODY, root: DOC } = this.dom

    BODY.removeChild(this.infoPanel)
    DOC.classList.remove(`is-${OFFLINE.toLowerCase()}`)

    this.panelHeight = 0
  }

  setFixedElementsTop() {
    if (!this.app.scroll) return

    const { header } = this.app.dom

    if (header) header.style.top = `${this.app.scroll.scrollbar.scrollTop + this.panelHeight}px`
  }

  onOffline = () => {
    this.insertInfoPanel()
    this.setFixedElementsTop()
  }

  onOnline = () => {
    this.removeInfoPanel()
    this.setFixedElementsTop()
  }

  init() {
    this.renderInfoPanel()
    if (!navigator.onLine) this.insertInfoPanel()
  }
}

import classNames from '../classNames'
import { IS_ACTIVE, IS_CURRENT, IS_OPEN, STATE } from '../constants'
import BEMBlock from '../lib/BEMBlock'

export default () => ({
  listToggler: (() => {
    const contactsWrap = document.querySelector(`.${classNames.contacts.wrap}`)
    if (!contactsWrap) return {}

    const onChange = ({ target }) => {
      const btn = target.closest(`.${classNames.contacts.btn}`)
      if (!btn) return

      const { value } = btn
      const wrap = btn.closest(`.${classNames.contacts.wrap}`)
      const wrapList = btn.closest('.toggle-list') || btn.closest('.dropdown')
      const btnWrap = btn.closest('.toggle-list__item')
      const isSelect = btn.tagName.toLowerCase() === 'select'

      let blocks
      let targetBlocks

      if (wrapList || isSelect) {
        blocks = [...wrap.querySelectorAll(`.${classNames.contacts.addresses}`)]
        targetBlocks = [
          ...wrap.querySelectorAll(`.${classNames.contacts.addresses}[data-address="${value}"]`),
        ]
      }

      if (wrapList) {
        const btnWraps = [...wrapList.querySelectorAll('.toggle-list__item')]

        btnWraps.forEach(b => BEMBlock(b).removeMod(IS_CURRENT))
        if (btnWrap) BEMBlock(btnWrap).addMod(IS_CURRENT)

        blocks.forEach(b => b.removeAttribute(STATE))
        targetBlocks.forEach(b => b.setAttribute(STATE, IS_ACTIVE))
      }

      if (isSelect) {
        blocks.forEach(b => b.removeAttribute(STATE))
        targetBlocks.forEach(b => b.setAttribute(STATE, IS_ACTIVE))
      }
    }

    return {
      onChange,
    }
  })(),

  dropdownToggler: (() => {
    const selects = [...document.querySelectorAll(`.${classNames.contacts.dropdown}`)]
    if (!selects.length) return {}

    const toggleDropdown = el => {
      el.getAttribute(STATE) === IS_OPEN
        ? el.removeAttribute(STATE)
        : el.setAttribute(STATE, IS_OPEN)
    }

    const onClick = ({ target }) => {
      const checkbox = target.closest('.dropdown__checkbox')
      if (checkbox) return

      const opener = target.closest(`.${classNames.contacts.dropdown} .dropdown__opener`)

      if (!opener) {
        selects.forEach(select => select.removeAttribute(STATE))
        return
      }

      const dropdown = opener.closest(`.${classNames.contacts.dropdown}`)
      toggleDropdown(dropdown)
    }

    const handleAddressCheckbox = checkbox => {
      const { value, name } = checkbox
      const wrap = checkbox.closest(`.${classNames.contacts.wrap}`)

      if (!wrap) return

      const blocks = [
        ...wrap.querySelectorAll(`.${classNames.contacts.addressBlock}[data-name="${name}"]`),
      ]
      const targetBlocks = [
        ...wrap.querySelectorAll(
          `.${classNames.contacts.addressBlock}[data-name="${name}"][data-address-block="${value}"]`
        ),
      ]

      blocks.forEach(b => b.removeAttribute(STATE))
      targetBlocks.forEach(b => b.setAttribute(STATE, IS_ACTIVE))
    }

    const handleDropdownCheckbox = checkbox => {
      const dropdown = checkbox.closest(`.${classNames.contacts.dropdown}`)
      if (dropdown) {
        const opener = dropdown.querySelector('.dropdown__opener')
        const label = checkbox.closest('.dropdown__checkbox')

        toggleDropdown(dropdown)

        const span = label ? label.querySelector('span span') : null
        if (!span) return

        const title = span.innerText
        opener.innerHTML = title
      }
    }

    const onChange = ({ target }) => {
      const addressCheckbox = target.closest(`.${classNames.contacts.addressBtn}`)
      const dropdownCheckbox = target.closest(
        `.dropdown__checkbox .${classNames.contacts.btn}, .dropdown__checkbox .${classNames.contacts.addressBtn}`
      )

      if (addressCheckbox) handleAddressCheckbox(addressCheckbox)

      if (dropdownCheckbox) handleDropdownCheckbox(dropdownCheckbox)
    }

    return {
      onChange,
      onClick,
    }
  })(),
})

const getSelectValue = (select, triggerName) => {
  const options = [...select.options]

  const [activeOption] = options.filter(
    ({ value }) => value.toLowerCase() === triggerName.toLowerCase()
  )

  return activeOption ? activeOption.value : ''
}

export const setServiceSelects = (app, wrap, name) => {
  const select = wrap.querySelector('[name="service"]')

  const value = getSelectValue(select, name)
  select.value = value

  if (app.fieldsToggler) app.fieldsToggler.update()
}

export const setBrandSelects = (wrap, name) => {
  const select = wrap.querySelector('[name="brand"]')

  const value = getSelectValue(select, name)
  select.value = value
}

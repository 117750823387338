import { IS_ACTIVE, IS_OPEN, STATE } from '../constants'

export default () => {
  const showItem = (title, item) => {
    title.setAttribute(STATE, IS_ACTIVE)
    item.setAttribute(STATE, IS_OPEN)
    item.style.maxHeight = `${item.scrollHeight}px`
  }

  const hideItem = (title, item) => {
    title.removeAttribute(STATE)
    item.removeAttribute(STATE)
    item.style.maxHeight = ''
  }

  const onClick = ({ target }) => {
    const title = target.closest('.faq-item__title')
    if (!title) return

    const wrap = title.closest('.faq-item')
    const text = wrap.querySelector('.faq-item__text')

    if (title.getAttribute(STATE) === IS_ACTIVE) hideItem(title, text)
    else showItem(title, text)
  }

  return {
    onClick,
  }
}

export default {
  lazy: 'js-lazy',
  select: 'js-select',
  sideControls: 'js-side-controls',
  mask: 'js-mask-input',
  popup: 'js-popup',
  form: 'js-form',
  pricesTable: {
    wrap: 'js-prices-table',
    table: 'js-prices-table-body',
    btn: 'js-prices-table-btn',
  },
  route: {
    input: 'js-route-input',
    link: 'js-route-link',
    formWrap: 'js-route-form-wrap',
  },
  contacts: {
    wrap: 'js-contacts',
    btn: 'js-contacts-btn',
    addresses: 'js-contacts-address',
    addressBlock: 'js-contacts-address-block',
    addressBtn: 'js-contacts-address-btn',
    dropdown: 'js-contacts-dropdown',
    mapBtn: 'js-contacts-map-btn',
  },
  menu: {
    burger: 'js-burger',
    menu: 'js-menu',
  },
  subnav: {
    back: 'js-subnav-back',
  },
  slider: {
    container: 'js-slider',
    slider: {
      wrap: 'slider__wrap',
      prev: 'js-slider-prev',
      next: 'js-slider-next',
      pagination: 'js-slider-pagination',
      slide: 'swiper-slide',
      slideThumb: 'js-slider-thumb',
    },
    plugin: {
      container: 'swiper-container',
      initialized: 'swiper-container-initialized',
      activeSlide: 'swiper-slide-active',
    },
  },
  tabs: {
    wrap: 'js-tabs',
    tab: 'js-tabs-tab',
    item: 'js-tabs-item',
  },
  hiddenFieldsForm: {
    wrap: 'js-hidden-fields-form',
    field: 'js-hidden-fields-field',
    select: 'js-hidden-fields-select',
  },
}

import classNames from '../classNames'
import { preventScroll, allowScroll, isTouch } from '../helpers'
import preventTouchScroll from '../lib/preventTouchScroll'
import { setBrandSelects, setServiceSelects } from './setSelectsValues'
import toggleSelectsFocus from './toggleSelectsFocus'

export default async app => {
  const popups = [...document.querySelectorAll(`.${classNames.popup}`)]
  if (!popups.length) return

  const { default: Popup } = await import(/* webpackChunkName: "Popup" */ 'popup-simple/index.es')

  app.popup = new Popup({
    preventScroll: false,
  })
  app.popup.onOpen = () => {
    app.updateState({
      hasPopupOpen: true,
    })

    if (!app.state.hasMenuOpen) preventScroll()

    const target = app.popup?.popup

    const orderPopup = target?.id === 'popup-order' ? target : null

    if (orderPopup) {
      const serviceName = app.popup.btn?.dataset.service
      const brandName = app.popup.btn?.dataset.brand

      if (serviceName) {
        setServiceSelects(app, orderPopup, serviceName)
      }

      if (brandName) {
        setBrandSelects(orderPopup, brandName)
        toggleSelectsFocus(app)
      }
    }
  }

  app.popup.onClose = () => {
    app.updateState({
      hasPopupOpen: app.popup.openPopups.length > 0,
    })

    if (!app.state.hasPopupOpen && !app.state.hasMenuOpen) {
      allowScroll()
    }

    const getOrderPopup = () => app.popup.popups.find(({ id }) => id === 'popup-order')

    const orderPopup = app.popup?.name === '#popup-order' ? getOrderPopup() : null

    if (orderPopup) {
      const serviceSelect = orderPopup.querySelector('[name="service"]')
      const brandSelect = orderPopup.querySelector('[name="brand"]')

      if (serviceSelect) serviceSelect.value = ''
      if (brandSelect) brandSelect.value = ''

      if (app.fieldsToggler) app.fieldsToggler.update()
    }
  }

  if (isTouch && app.popup.popups.length > 0) {
    app.popup.popups.forEach(p => {
      const pts = preventTouchScroll(p)
      pts.init()
    })
  }
  app.popup.init()
}

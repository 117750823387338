import classNames from '../../classNames'

export default async app => {
  const forms = [...document.querySelectorAll(`.${classNames.hiddenFieldsForm.wrap}`)]

  if (!forms.length) return

  const { default: FieldsToggler } = await import(
    /* webpackChunkName: "FieldsToggler" */ './FieldsToggler'
  )

  app.fieldsToggler = new FieldsToggler(forms, app)
  app.fieldsToggler.init()
}

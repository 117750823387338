import './public-path'
import { debounce, throttle } from 'throttle-debounce'
import classNames from './classNames'
import { isModernBrowser } from './helpers'
import { DELAYS } from './constants'

import loadPolyfills from './polyfills/loadPolyfills'
import setHTMLClassNames from './methods/setHTMLClassNames'
import handleSubmenu from './components/Menu/handleSubmenu'
import toggleSelectsFocus from './methods/toggleSelectsFocus'
import toggleContacts from './methods/toggleContacts'
// import toggleThemeColor from './methods/toggleTemeColor'
import setSelects from './methods/setSelects'
import setInputMask from './methods/setInputMask'
import setPopups from './methods/setPopups'
import setRouteLink from './methods/setRouteLink'
import handleForms from './methods/handleForms'
import toggleHeader from './methods/toggleHeader'
import faqAccordion from './methods/faqAccordion'
import { setDynamicDate, toggleHeroNav } from './methods/common'

import setLazy from './components/LazyLoader/setLazy'
import Menu from './components/Menu/Menu'
import Offline from './components/Offline/Offline'
import setSlider from './components/Slider/setSlider'
import setTabs from './components/Tabs/setTabs'
import setFieldsToggler from './components/FieldsToggler/setFieldsToggler'
import setMap from './components/Map/setMap'
import setPricesTable from './components/PricesTable/setPricesTable'

class App {
  constructor() {
    this.methods = {}
    this.classNames = classNames
    this.dom = {
      root: document.documentElement,
      body: document.body,
      header: document.querySelector('.out__header'),
      main: document.querySelector('.out__main'),
      sideControls: document.querySelector(`.${classNames.sideControls}`),
      submenu: {
        menus: [...document.querySelectorAll('.subnav')],
        btnWraps: [...document.querySelectorAll('.nav__item--has-submenu')],
      },
    }
    this.LANGUAGE = this.dom.root.getAttribute('lang')
      ? this.dom.root.getAttribute('lang').split('-')[0].toLowerCase()
      : 'en'
    this.state = {
      hasMenuOpen: false,
    }
    this.LANGUAGE = this.dom.root.getAttribute('lang')
      ? this.dom.root.getAttribute('lang').split('-')[0].toLowerCase()
      : 'en'

    this.menu = new Menu(this)
    this.offline = new Offline(this)
    this.submenu = handleSubmenu(this)
    this.contactsToggler = toggleContacts(this)
    this.routeLink = setRouteLink(this)
    this.headerToggler = toggleHeader(this)
    this.faqAccordion = faqAccordion(this)

    this.slider = null
    this.tabs = null
    this.masks = []
    this.nativeSelects = []
  }

  updateState(state) {
    this.state = {
      ...this.state,
      ...state,
    }
  }

  async initMethods() {
    setLazy(this)
    setSlider(this)
    toggleSelectsFocus(this)
    setTabs(this)
    setSelects(this)
    setInputMask(this)
    setPopups(this)
    handleForms(this)
    setFieldsToggler(this)
    setMap(this)
    await setPricesTable(this)
    setDynamicDate()
    toggleHeroNav()
  }

  // ========= start event listeners ===================
  onChangeHandler = e => {
    this.contactsToggler.listToggler.onChange?.(e)

    this.contactsToggler.dropdownToggler.onChange?.(e)

    this.routeLink.onChange?.(e)
  }

  onClickHandler = e => {
    this.contactsToggler.dropdownToggler.onClick?.(e)
    this.submenu.onClick(e)
    this.tabs?.onClick?.(e)
    this.menu.onClick?.(e)
    this.faqAccordion.onClick(e)
    this.pricesTable?.onClick?.(e)
  }

  onResizeHandler = e => {
    debounce(DELAYS.long, this.submenu.onResize(e))
  }

  onScrollHandler = e => {
    throttle(DELAYS.min, this.headerToggler.onScroll(e))
  }

  onMouseOverHandler = e => {
    this.headerToggler.onMouseOver(e)
  }

  onKeyupHandler = e => {
    this.submenu.onKeyup(e)
    this.menu.onKeyUp?.(e)
  }

  onOfflineHandler = () => {
    this.offline.onOffline()
  }

  onOnlineHandler = () => {
    this.offline.onOnline()
  }

  // ========= end event listeners ===================
  addListeners() {
    document.addEventListener('click', this.onClickHandler)
    document.addEventListener('change', this.onChangeHandler, true)
    document.addEventListener('keyup', this.onKeyupHandler)
    document.addEventListener('mouseover', this.onMouseOverHandler)
    window.addEventListener('resize', this.onResizeHandler)
    window.addEventListener('scroll', this.onScrollHandler)

    window.addEventListener('offline', this.onOfflineHandler)
    window.addEventListener('online', this.onOnlineHandler)
  }

  init() {
    this.initMethods()
    this.addListeners()

    this.menu.init()
    this.offline.init()
  }
}

const init = () => {
  const app = new App()
  app.init()
}

const onLoad = () => {
  setHTMLClassNames()
}

if (isModernBrowser) {
  document.addEventListener('DOMContentLoaded', init)
} else {
  document.addEventListener('DOMContentLoaded', loadPolyfills.bind(null, init))
}

window.addEventListener('load', onLoad)

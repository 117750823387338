import classNames from '../classNames'

export default async () => {
  const selects = [...document.querySelectorAll(`.${classNames.select}`)]
  if (!selects.length) return

  const { default: Select } = await import(/* webpackChunkName: "Select" */ 'select-custom')

  selects.forEach(select => {
    const s = new Select(select)
    s.init()
  })
}

import classNames from '../../classNames'
import { IS_ACTIVE, IS_OPEN, STATE } from '../../constants'
import { allowScroll, preventScroll } from '../../helpers'

export default app => {
  const {
    dom: {
      root: DOC,
      submenu: { menus, btnWraps },
    },
  } = app

  const TRANSITION_DURATION = 400

  const isMobile = () => !window.matchMedia('(min-width: 768px)').matches

  const getOffsetHeight = ({ scrollHeight }) => {
    const headerTopHeight = 47

    return scrollHeight > window.innerHeight - headerTopHeight
      ? `calc(100vh - ${headerTopHeight}px)`
      : `${scrollHeight}px`
  }

  const onTransitionEnd = ({ target }) => {
    const menu = target.classList.contains('nav__subnav') ? target : null
    if (!menu) return

    btnWraps.forEach(item => {
      const btn = item.querySelector('.nav__link')
      if (btn) btn.disabled = false
    })

    if (!isMobile()) {
      menu.getAttribute(STATE) === IS_OPEN
        ? menu.removeAttribute(STATE)
        : menu.setAttribute(STATE, IS_OPEN)
    }
  }

  const openSubnav = ({ btnWrap, menu }) => {
    menus.forEach(m => {
      m.style.maxHeight = ''
    })
    btnWraps.forEach(item => {
      const btn = item.querySelector('.nav__link')

      btn.disabled = true
      item.removeAttribute(STATE)
    })

    setTimeout(onTransitionEnd.bind(null, { target: menu }), TRANSITION_DURATION)

    if (isMobile()) {
      menu.setAttribute(STATE, IS_OPEN)
    } else {
      const images = [...menu.querySelectorAll(`.${classNames.lazy}`)]

      const slideDown = () => {
        menu.style.maxHeight = getOffsetHeight(menu)

        if (!isMobile()) {
          app.dom.main.style.transform = `translate(0, ${getOffsetHeight(menu)})`
        }
      }

      if (images.length > 0) {
        images.forEach((img, i) => {
          if (img.getAttribute('data-processed') === 'true' && i === images.length - 1) {
            slideDown()
            return
          }

          if (img.getAttribute('data-processed') !== 'true' && app.lazyLoader) {
            app.lazyLoader.processElement(img)
            app.lazyLoader.loader.markAsProcessed(img)

            if (i === images.length - 1) {
              app.lazyLoader.onLoad = slideDown
            }
          }
        })
      } else {
        slideDown()
      }
    }

    btnWrap.setAttribute(STATE, IS_ACTIVE)

    preventScroll()

    DOC.classList.add('has-open-subnav')
    app.updateState({
      hasOpenSubnav: true,
    })
  }

  const closeSubnav = (state = {}) => {
    const { menu, btnWrap } = state

    if (state === 'all') {
      menus.forEach(m => {
        m.removeAttribute(STATE)
        m.style.maxHeight = ''
      })

      btnWraps.forEach(item => item.removeAttribute(STATE))
    } else if (menu && btnWrap) {
      menu.style.maxHeight = ''
      menu.removeAttribute(STATE)

      btnWrap.removeAttribute(STATE)
    }

    if (!isMobile()) app.dom.main.style.transform = ''

    if (!app.state.hasPopupOpen && !app.state.hasMenuOpen) allowScroll()
    DOC.classList.remove('has-open-subnav')
    app.updateState({
      hasOpenSubnav: false,
    })
  }

  const onClick = e => {
    const { target } = e

    const openBtn = target.closest('.nav__item--has-submenu .nav__link')
    const backBtn = target.closest(`.${classNames.subnav.back}`)

    if (!target.closest('.nav__subnav') && !target.closest('.nav__link')) closeSubnav('all')

    if (openBtn) {
      e.preventDefault()
      const [menu] = menus.filter(m => m === openBtn.nextElementSibling)
      const btnWrap = openBtn.closest('.nav__item--has-submenu')

      if (btnWrap.getAttribute(STATE) === IS_ACTIVE) {
        closeSubnav({ btnWrap, menu })
      } else {
        openSubnav({ btnWrap, menu })
      }
    }

    if (backBtn) {
      const menu = backBtn.closest('[data-state="open"]')
      const btnWrap = backBtn.closest('.nav__item--has-submenu')

      closeSubnav({ btnWrap, menu })
    }
  }

  const onKeyup = ({ code }) => {
    if (code !== 'Escape') return

    closeSubnav('all')
  }

  const onResize = () => {
    if (isMobile()) return
    const subnavs = [...document.querySelectorAll('.subnav')]
    const buttons = [...document.querySelectorAll('.nav__item--has-submenu')]

    if (subnavs.length > 0 && buttons.length > 0) closeSubnav('all')
  }

  return {
    onClick,
    onKeyup,
    onResize,
  }
}

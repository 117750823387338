const checkPresence = input => input.value.trim() !== ''

const checkEmail = input => {
  const email = input.value
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const checkPhone = (maskInput, input) => {
  const value = maskInput?.mask.value
  const countryCode = value && value.slice(0, 3)

  return maskInput?.mask.masked.isComplete && countryCode === '+38'
}

const messages = {
  default: {
    ru: 'Поле обязательно для заполнения',
    uk: "Поле обов'язкове для заповнення",
  },
  email: {
    ru: 'Введите корректный email',
    uk: 'Введіть коректний email',
  },
  phone: {
    ru: 'Введите корректный номер телефона',
    uk: 'Введіть коректний номер телефону',
  },
}

export { checkPresence, checkEmail, checkPhone, messages }

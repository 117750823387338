import classNames from '../classNames'

export default () => {
  const inputs = [...document.querySelectorAll(`.${classNames.route.input}`)]
  if (!inputs.length) return {}

  const setUrl = (link, url) => {
    link.setAttribute('href', url)
  }

  inputs.forEach(input => {
    if (!input.checked) return
    const popup = input.closest(`.${classNames.route.formWrap}`)
    const link = popup.querySelector(`.${classNames.route.link}`)
    const url = input.value
    setUrl(link, url)
  })

  const onChange = ({ target }) => {
    const checkbox = target.closest(`.${classNames.route.input}`)

    if (!checkbox) return

    const popup = checkbox.closest(`.${classNames.route.formWrap}`)
    const link = popup.querySelector(`.${classNames.route.link}`)
    const url = checkbox.value

    setUrl(link, url)
  }

  return {
    onChange,
  }
}

import classNames from '../../classNames'

export default async app => {
  const sliders = [...document.querySelectorAll(`.${classNames.slider.container}`)]
  if (!sliders.length) return

  const { default: Slider } = await import(/* webpackChunkName: "Slider" */ './Slider')

  app.slider = new Slider(`.${classNames.slider.container}`, app)
  app.slider.init()
}

import { IS_OPEN, STATE } from '../constants'

export const setDynamicDate = () => {
  const yearTag = document.getElementById('js-year')
  if (yearTag) yearTag.outerHTML = new Date().getFullYear()
}

export const toggleHeroNav = () => {
  const navs = [...document.querySelectorAll('.hero__nav')]
  if (!navs.length) return

  const onBtnClick = nav => () => {
    if (nav.getAttribute(STATE) === IS_OPEN) {
      nav.removeAttribute(STATE)
    } else {
      nav.setAttribute(STATE, IS_OPEN)
    }
  }

  navs.forEach(nav => {
    const btn = nav.querySelector('button')
    if (!btn) return

    const list = nav.querySelector('.nav__list')
    if (!list) return

    btn.addEventListener('click', onBtnClick(nav))
  })
}

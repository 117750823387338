import classNames from '../classNames'

export default async app => {
  const maskedInputs = [...document.querySelectorAll(`.${classNames.mask}`)]

  if (!maskedInputs.length) return

  function getOptions(mask) {
    const placeholderChar = '_'

    return {
      phone: {
        placeholderChar,
        mask,
        lazy: true,
      },
      number: {
        mask: Number,
      },
    }
  }

  const init = IMask => {
    maskedInputs.forEach(input => {
      const type = input.dataset.maskType || 'phone'
      const mask = input.dataset.mask || '+{38\\0}(00)000-00-00'

      const imask = IMask(input, getOptions(mask)[type])

      const maskObj = {
        inputValue: '',
        input,
        type,
        mask: imask,
      }

      app.masks.push(maskObj)

      input.addEventListener('input', () => {
        maskObj.inputValue = imask.masked.rawInputValue
      })
      input.addEventListener('focus', () => {
        imask.updateOptions({
          lazy: false,
        })
      })
      input.addEventListener('blur', () => {
        imask.updateOptions({
          lazy: true,
        })
        imask.value = maskObj.inputValue
      })
    })
  }

  if (!('globalThis' in window))
    await import(/* webpackChunkName: "global-this-polyfill" */ 'core-js/features/global-this')

  const { default: IMask } = await import(/* webpackChunkName: "imask" */ 'imask')

  init(IMask)
}

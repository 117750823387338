import classNames from '../classNames'
import { HAS_TEXT } from '../constants'
import BEMBlock from '../lib/BEMBlock'

export default app => {
  const selects = [...document.querySelectorAll(`select:not(.${classNames.select})`)]

  if (!selects) return

  const setFocus = ({ value }, wrap) => {
    if (value !== 'placeholder' && value !== '') {
      BEMBlock(wrap, 'input').addMod(HAS_TEXT)
    } else {
      BEMBlock(wrap, 'input').removeMod(HAS_TEXT)
    }
  }

  const onChange = wrap => ({ currentTarget }) => {
    setFocus(currentTarget, wrap)
  }

  selects.forEach(select => {
    const wrap = select.closest('.input')
    if (!wrap) return

    setFocus(select, wrap)

    const isPushed = app.nativeSelects.find(({ wrap: w }) => wrap === w)

    if (!isPushed) {
      select.addEventListener('change', onChange(wrap))

      app.nativeSelects.push({
        wrap,
        el: select,
        setFocus,
      })
    }
  })
}

import classNames from '../../classNames'

export default async app => {
  const table = document.querySelector(`.${classNames.pricesTable.wrap}`)
  if (!table) return

  const { default: PricesTable } = await import(
    /* webpackChunkName: "PricesTable" */ './PricesTable'
  )

  app.pricesTable = new PricesTable(table, app)
  await app.pricesTable.init()
}

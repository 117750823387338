export const {
  STATE,
  IS_LIGHT,
  IS_DARK,
  IS_ACTIVE,
  IS_HIDDEN,
  IS_CURRENT,
  IS_OPEN,
  IS_READY,
  IS_LOADED,
  IS_SHRINKED,
  IS_INITED,
  IS_RENDERED,
  NO_TOUCH,
  HAS_ERROR,
  HAS_FOCUS,
  HAS_TEXT,
  OFFLINE,
  DELAYS,
} = {
  STATE: 'data-state',
  IS_LIGHT: 'light',
  IS_DARK: 'dark',
  IS_ACTIVE: 'active',
  IS_HIDDEN: 'hidden',
  IS_CURRENT: 'current',
  IS_OPEN: 'open',
  IS_READY: 'is-ready',
  IS_LOADED: 'loaded',
  IS_SHRINKED: 'shrinked',
  IS_INITED: 'initialized',
  IS_RENDERED: 'rendered',
  NO_TOUCH: 'no-touch',
  HAS_ERROR: 'error',
  HAS_FOCUS: 'focus',
  HAS_TEXT: 'has-text',
  OFFLINE: 'offline',
  DELAYS: {
    min: 66,
    short: 100,
    medium: 200,
    long: 300,
  },
}

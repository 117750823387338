export default async app => {
  const wrap = document.getElementById('map')
  if (!wrap) return

  const { default: Map } = await import(/* webpackChunkName: "Map" */ './Map')

  app.map = new Map(wrap)

  const handleIntersecting = (entries, observer) => {
    entries.forEach(({ isIntersecting, target }) => {
      if (isIntersecting) {
        app.map.init()
        observer.unobserve(target)
      }
    })
  }

  const observer = new IntersectionObserver(handleIntersecting)
  observer.observe(wrap)
}

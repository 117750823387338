import { IS_SHRINKED } from '../constants'
import BEMBlock from '../lib/BEMBlock'

export default ({ dom: { header } }) => {
  const shrink = () => BEMBlock(header, 'header').addMod(IS_SHRINKED)
  const expand = () => BEMBlock(header, 'header').removeMod(IS_SHRINKED)

  const onScroll = () => {
    if (window.pageYOffset > 50) {
      shrink()
    } else {
      expand()
    }
  }

  const onMouseOver = ({ target }) => {
    if (target.closest('.header__top')) expand()
  }

  return {
    onScroll,
    onMouseOver,
  }
}
